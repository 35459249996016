import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const AccelerationIntro = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="relative">
        <div className="bg-blue-bg-dark section-custom-top">
          <div className="container relative z-10">
            <div className="mx-auto w-full max-w-[516px] relative z-20">
              <p className="text-24 mt-32 font-semibold">
                <Trans>
                  Milkomeda is a groundbreaking new protocol that brings EVM
                  capabilities to non-EVM blockchains.
                </Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  As development progresses, Milkomeda will expand to offer L2
                  solutions (rollups) for several major blockchains including
                  Algorand, Solana and Cardano.
                  <br />
                  <br />
                  Our acceleration program is intended to provide startups
                  building on Milkomeda with grant funding, priority access to
                  resources, promotion and support to help your startup take
                  flight.
                </Trans>
              </p>
              <p className="text-24 mt-32 font-semibold">
                <Trans>
                  Join us to be among the first wave of startups launching on
                  the new Cardano EVM compatible scalable solution!
                </Trans>
              </p>
              <div className="md:flex-row mt-40 md:mt-40 text-center">
                <div className="btn-disabled w-full px-80 mb-30 md:mb-0">
                  <Trans>Join now</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-blue-bg-dark">
          <div className="container z-20">
            <h2 className="heading-2 pt-80 px-16">
              <Trans>Selected Companies Receive</Trans>
            </h2>
            <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
              <li className="card-item card card-b">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/acceleration/funding2x.webp 2x, /images/icons/acceleration/funding.webp 1x"
                      src="/images/icons/acceleration/funding.png"
                      width="88"
                      height="88"
                      alt="funding"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>$10k USDC funding</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal px-45">
                      <Trans>$10k USDC non-dilutive grant funding</Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-300">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/acceleration/benefits2x.webp 2x, /images/icons/acceleration/benefits.webp 1x"
                      src="/images/icons/acceleraation/benefits.webp"
                      width="88"
                      height="88"
                      alt="benefits"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>6+ weeks of benefits</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        6+ weeks of hands-on programming, mentorship, and
                        priority technical support from dcSpark and dlab
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-600">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/acceleration/promotion2x.webp 2x, /images/icons/acceleration/promotion.webp 1x"
                      src="/images/icons/acceleraation/promotion.webp"
                      width="88"
                      height="88"
                      alt="promotion"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Featured status and promotion</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Promotion of the project within Milkomeda’s
                        communication channels
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-700">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/acceleration/livestream2x.webp 2x, /images/icons/acceleration/livestream.webp 1x"
                      src="/images/icons/acceleraation/livestream.webp"
                      width="88"
                      height="88"
                      alt="livestream"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Live-streamed program</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Live-streamed program showcase where you’ll demo your
                        product to new users
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-800 mt-24">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/acceleration/access2x.webp 2x, /images/icons/acceleration/access.webp 1x"
                      src="/images/icons/acceleraation/access.webp"
                      width="88"
                      height="88"
                      alt="access"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Access to future funding options</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        Access to future funding options from dlab and program
                        partner VCs investing into the Milkomeda ecosystem
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
              <li className="card-item card card-b animation-delay-900 mt-24">
                <div className="box-item bg-blue-bg-dark py-36 px-24">
                  <div className="w-88 h-88 mx-auto">
                    <img
                      className="image-sd"
                      srcSet="/images/icons/acceleration/global2x.webp 2x, /images/icons/acceleration/global.webp 1x"
                      src="/images/icons/acceleraation/global.webp"
                      width="88"
                      height="88"
                      alt="global network"
                    />
                  </div>
                  <div className="mt-20 mb-auto">
                    <p className="text-24 font-semibold">
                      <Trans>Global Network</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal">
                      <Trans>
                        You will join a network of specialists, global
                        entrepreneurs, and experienced international mentors
                      </Trans>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccelerationIntro;
