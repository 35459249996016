import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const AccelerationContact = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="relative" id="apply">
        <div className="bg-blue-bg-dark section-custom-top">
          <div className="container relative z-10">
            <div className="mx-auto w-full max-w-[636px] relative z-20">
              <h2 className="heading-2">
                <Trans>BATCH 1 APPLICATIONS CLOSED</Trans>
              </h2>
              <p className="mt-20 text-menu-white leading-normal text-center">
                <Trans>
                  Applications are reviewed on a first-come, first-serve basis.
                  Priority treatment will be given to applications received on
                  or <b>before 22/04/2022</b>. Questions about the grants or our
                  process? Email&nbsp;
                  <a
                    className="text-orange-light"
                    href="mailto:milkomeda@dlab.vc"
                  >
                    milkomeda@dlab.vc
                  </a>
                  &nbsp;or slide into our dms @dlabvc.
                  <br /> <br />
                  We look forward to learning more about what you're working on!
                </Trans>
              </p>
              {/* <form
                name="acceleration"
                className="mt-50"
                method="post"
                data-netlify="true"
              > */}
              <input type="hidden" name="form-name" value="acceleration" />
              <div className="input-custom">
                <label htmlFor="name">
                  <Trans>Company owner name/surname *</Trans>
                </label>
                <input type="text" name="owner name" required />
              </div>
              <div className="input-custom mt-16">
                <label htmlFor="company-name">
                  <Trans>Company name *</Trans>
                </label>
                <input type="text" name="company name" required />
              </div>
              <div className="input-custom mt-15">
                <label htmlFor="email">
                  <Trans>Direct contact (e-mail) *</Trans>
                </label>
                <input type="email" name="email" required />
              </div>
              <div className="md:flex flex-row justify-between">
                <div className="input-custom mt-16  md:w-300">
                  <label htmlFor="website">
                    <Trans>Website URL *</Trans>
                  </label>
                  <input type="text" name="website" required />
                </div>
                <div className="input-custom mt-16 md:w-300">
                  <label htmlFor="twitter">
                    <Trans>Twitter *</Trans>
                  </label>
                  <input type="text" name="twitter" required />
                </div>
              </div>
              <div className="input-custom mt-15">
                <label>
                  <Trans>
                    What are you building? Describe it in 140 characters or
                    less. *
                  </Trans>
                </label>
                <textarea name="project idea" rows={3} required></textarea>
              </div>
              <div className="input-custom mt-15">
                <label>
                  <Trans>
                    Describe your project: What's your value proposition? What
                    stage are you at? What makes your project unique? *
                  </Trans>
                </label>
                <textarea
                  name="project description"
                  rows={3}
                  required
                ></textarea>
              </div>
              <div className="input-custom mt-15">
                <label>
                  <Trans>
                    Describe any relevant experience the founders have. Who are
                    you? How long have you worked together? Let us know if this
                    is your first startup and if you are 100% involved in it. *
                  </Trans>
                </label>
                <textarea name="team background" rows={3} required></textarea>
              </div>
              <div className="input-custom mt-15">
                <label>
                  <Trans>
                    Have you already registered your company? If so, what type
                    of entity is it and where is it registered? Have you raised
                    any money? *
                  </Trans>
                </label>
                <textarea name="legal status" rows={3} required></textarea>
              </div>
              <div className="input-custom mt-15">
                <label>
                  <Trans>
                    Aside from funding, where do you need the most help at this
                    stage in your project? *
                  </Trans>
                </label>
                <textarea name="project needs" rows={3} required></textarea>
              </div>
              <div className="input-custom mt-15">
                <label>
                  <Trans>
                    Provide links to your project documentation (optional)
                  </Trans>
                </label>
                <textarea name="documentation" rows={3}></textarea>
              </div>
              <button
                className="btn-disabled mt-40 w-full"
                disabled
                type="submit"
              >
                <Trans>Submit</Trans>
              </button>
              {/* </form> */}
            </div>
          </div>
        </div>

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccelerationContact;
