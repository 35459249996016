import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const AccelerationLanding = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="w-full relative z-20 -mb-10">
        <div className="absolute w-full h-full top-0 left-0 -z-10">
          <img
            className="w-full h-full object-cover"
            srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
            src="/images/page-bg.webp"
            width="1600"
            height="502"
            alt="page-bg"
          />
        </div>
        <div className="container pt-110 lg:pt-145 sc-up-mb z-20 relative">
          <div className="text-center animate-from-bottom">
            <h1 className="heading-3 heading-1-2 ml-100">
              <Trans>Launch your Web3 Startup on Milkomeda C1</Trans>
            </h1>
            <p className=" align-center mt-24 text-menu-white font-normal md:text-24">
              <Trans>
                Access grant funding, investment, and step-by-step guidance from
                global folks
                <br />
                that have been building the ecosystem.
              </Trans>
            </p>
            <div className="box-item mt-24 px-16 py-16 bg-see-through-gray max-w-[880px] ml-auto mr-auto">
              <p className="text-28 mb-16 font-semibold">
                <Trans>BATCH 1 APPLICATIONS CLOSED</Trans>
              </p>
              <p className="text-16 sm:text-20 leading-2 sm:leading-normal text-menu-white">
                Applications for Batch 1 are now closed. We are very excited
                about the first Batch and with a very high number of
                applications, we can see that the community is very excited as
                well. Thank you, everyone! All applicants will be notified by
                mid-May 2022.
              </p>
            </div>
            <div className="md:flex-row mt-40 md:mt-40">
              <div className="btn-disabled px-80 mb-30 md:mb-0">
                <Trans>Apply now</Trans>
              </div>
            </div>
            <div className="flex md:flex-row justify-center items-center mt-24 lg:mt-24 text-menu-white text-center relative z-10">
              <Trans>Powered by</Trans>
              <div className="text-center mx-5">
                <a
                  href="https://www.dcspark.io/"
                  target="_blank"
                  rel="noopener"
                  className="link"
                >
                  <Trans>dcSpark</Trans>
                </a>
              </div>
              <Trans> & </Trans>
              <div className="text-center mx-5">
                <a
                  href="https://dlab.vc/"
                  target="_blank"
                  rel="noopener"
                  className="link"
                >
                  <Trans>dlab</Trans>
                </a>
              </div>
            </div>
          </div>

          <div className="space-custom"></div>
        </div>

        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccelerationLanding;
